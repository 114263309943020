import React, { useState, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"

import "./results.scss"

import EggCursor from "../components/eggcursor";
import ResultsTable from "../components/resultstable";

const ResultsPage = ({location}) => {
  const params = new URLSearchParams(location.search);
  const [handle, setHandle] = useState(params.get("handle"));
  const [shareUrl, setShareUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  
  if (!String(handle).startsWith("@")) setHandle("@" + handle);

  useEffect(() => {
    setShareUrl('https://twitter.com/intent/tweet?url=https%3A%2F%2Fpoached.xyz&text=Hire%20'+handle+'\'s%20followers%20using%20the%20latest%20%40PartyRound%20drop.');
    setDownloadUrl('http://api.poached.xyz/csv?handle='+ handle);
  }, [handle, setDownloadUrl, setShareUrl]);

  return (
    <>
      <Seo/>
      <main>
        <EggCursor />
        <div className="position-absolute result-links ">
          <a href="/" target="_blank" rel="noreferrer">
            <StaticImage src="../images/results_btn-home.png" alt="Home" placeholder="none" width={100}/>
          </a>
          <a className="d-none d-lg-block" href={shareUrl} target="_blank" rel="noreferrer">
            <StaticImage src="../images/results_btn-share.png" alt="Home" placeholder="none" width={100}/>
          </a>
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            <StaticImage src="../images/results_btn-download.png" alt="Home" placeholder="none" width={100}/>
          </a>
        </div>

        <div className="container d-flex justify-content-center">
          <a 
            className="sharePoach"
            href={shareUrl}
            target="_blank" rel="noreferrer"
          >
            <StaticImage src="../images/results_sharebtn.png" alt="Share" placeholder="none" width={236}/>
          </a>
        </div>
        
        <div className="results container d-flex justify-content-center">
          <div className="card d-flex justify-content-center  px-5">
            <h6 className="text-center">Share to twitter to get these followers hired!</h6>
            <h1 className="text-center">{handle}’S POACHED EGGS</h1>
            <ResultsTable handle={handle}/>
          </div>
        </div>
      </main>
    </>
  )
}

export default ResultsPage