import React, { useState, useEffect } from "react"

import "./resultstable.scss"

const ResultsTable = (props) => {
    const [poachlist, setPoachlist] = useState(0)
    const handle = props.handle;

    useEffect(() => {
        var url = new URL('https://api.poached.xyz/list')

        const params = {
            handle: handle,
        };

        url.search = new URLSearchParams(params).toString();

        fetch(url)
        .then(response => {
            return response.json().then(function(data) {
                const result = data.rows.map((row) => {
                    return (
                        <tr>
                            <td>{row[2]}</td>
                            <td>@{row[1]}</td>
                            <td>{row[6]}</td>
                            <td>{row[5]}</td>
                            <td>{row[9]}</td>
                            <td className="text-center"><a href={row[7]}>@{row[1]} Profile</a></td>
                        </tr>
                    )
                });
                setPoachlist(result)
            });
          });
    }, [handle])
        
  return (
    <table className="table table-sm table-borderless">
        <tr>
            <th>Name</th>
            <th>Handle</th>
            <th>Role</th>
            <th>Company</th>
            <th>Tag</th>
            <th>LinkedIn</th>
        </tr>
        {poachlist}
    </table>
  )
}

export default ResultsTable